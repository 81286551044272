import './App.css';
import logo from "../src/assets/images/logo-sm.png"
import { Button } from "reactstrap"
import ChatBot from "./components/Chatbot/Index"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" component={ChatBot} />
        </Routes>
      </Router>
      <ChatBot />
    </>
  );
}

export default App;
