import React, { Component } from 'react'
import logo from "../../assets/images/logo-sm.png"
import logo1 from "../../assets/images/Logo.png"
import ChatOptions from './ChatOptions';
import OptionRequests from './optionRequests';
import calculatorData from "../../db.json";

const iframe = '<iframe src="https://millenniapay.com/" width="100%" frameborder="0" style="height: 100vh;"></iframe>'; 

export default class Index extends Component {
  constructor() {
    super();
    this.state = {
      showChatBot: false,
      showChatPopUp: true,
      openOptionReq: false,
      screen:1
    }
  }
  

  showChatPopUp = () => {

    this.setState({
      showChatPopUp: !this.state.showChatPopUp

    })

    if(this.state.screen==2 )
    {
      this.setState({
        openOptionReq: !this.state.showChatPopUp

      })
      
    }
  }

  getUpdate = (item) => {
    this.setState({
      openOptionReq: item,
      screen:2
    })
  }
  OptionRequest = (info) => {
    this.setState({
      openOptionReq: info.openOptionReq,
      showChatPopUp:info.openOptionReq,
    })
  }

  backOption()
  {
    this.setState({
      showChatPopUp: true,
      screen:1,
      openOptionReq:false

    })

  }




  UNSAFE_componentWillReceiveProps(){
    this.props.OptionRequest();
    this.props.getUpdate();
  } 

  componentDidMount() {

  }
  render() {
    const { openOptionReq, showChatPopUp } = this.state
    return (
      <>
      <div dangerouslySetInnerHTML={ {__html:  iframe}} />
          { openOptionReq == true &&
            <OptionRequests backOption={this.backOption.bind(this)}   OptionRequest={this.OptionRequest.bind(this)} /> 
          }
        
        <div className="chat-position " >
          { openOptionReq == false &&
            <React.Fragment>
              <div className="chat-window-sml p-0 chat_box_header" style={{ display: showChatPopUp ? "" : "none" }}>
                <div className="header">
                  <div className="logo_parent">
                    <div className="logo">
                      <img src={logo1} alt="" />
                    </div>
                    <p class="milliennia f-bold">Milliennia</p>
                  </div>
                  <a href="javascript:;" onClick={this.showChatPopUp}  class="options-close chat_close_icon"><i class="fa fa-times"></i></a>
                </div>
                <div className="chat_box_body">
                  <h2>&#x1F44B; Hi there!</h2>
                  <p>How can we help you today?</p>
                  
                  { openOptionReq == false &&
                    <ChatOptions getUpdate={this.getUpdate.bind(this)} /> 
                  }
                  {/* <ChatOptions getUpdate={this.getUpdate.bind(this)} /> */}

                  {/* <OptionRequests /> */}
                </div>
              </div>

              <div onClick={this.showChatPopUp} className="chat-button" >
                Chat with us <img src={logo} className="ml-2" width="40" />
              </div>
            </React.Fragment>
          }
         
        </div>
      </>
    )
  }
}
