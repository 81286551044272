import React, { Component } from 'react'

export default class ChatOptions extends Component {
    constructor(){
        super();
        this.state = {
            showChatBot:false,
            openOptionReq:false,
        }
        this.showChatBot = this.showChatBot.bind(this)
        this.openOptionReq = this.openOptionReq.bind(this)
    }
    showChatBot = () => {
        this.setState({
            showChatBot:!this.state.showChatBot
        })
    }
    openOptionReq = () => {
       
        const { showChatBot  } = this.state
        this.setState({
            openOptionReq:true
        },() => {
            this.props.getUpdate(true)
        })

    }



    componentDidMount = () => {
        // this.openOptionReq();
    }
    render() {
        console.log(this.props.getUpdate)
        const { showChatBot } = this.state
        return (
            <>
                <div className="bot-options">
                    <a href="javascript:;" onClick={this.openOptionReq} className="option-btn list-group-item">Make a Payment</a>
                    <a href="" className="option-btn list-group-item">Pull a Balance</a>
                    <a href="" className="option-btn list-group-item">Retrieve a statement</a>
                    <a href="" className="option-btn list-group-item">Create a portal account</a>
                    <div id="moreOptions" className="css-more-options" style={{display:showChatBot ? "block" : "none"}}>
                        <a href="" className="option-btn list-group-item">Change Password</a>
                        <a href="" className="option-btn list-group-item">Common Questions</a>
                        <a href="" className="option-btn list-group-item">Speak to an agent</a>
                        <a href="" className="option-btn list-group-item">Payment Plan</a>
                    </div>
                    <a href="#" onClick={this.showChatBot}  className="more-options">More</a>
                </div>
            </>
        )
    }
}
