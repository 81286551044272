import React, { Component } from 'react'
import logo from "../../assets/images/Logo.png"
import close from "../../assets/images/Close.svg"
import calculatorData from "../../db.json";
import { Button, Spinner } from 'reactstrap';
import SecureForm from '../Forms/secureForm';
import PaymentForm  from "../Forms/paymentForm"
import { Link } from 'react-router-dom';

export default class optionRequests extends Component {
    constructor() {
        super();
        this.state = {
            closePopUp: true,
            chatboots: [],
            clickId: 1,
            loading: false,
            openSecureForm:false,
            openPaymenteForm:false
        }
        this.chat_scroll = React.createRef();
        this.ClosePopUp = this.ClosePopUp.bind(this);
        this.OpenSecureForm = this.OpenSecureForm.bind(this);
        this.selectOptions = this.selectOptions.bind(this);
        this.backOption = this.backOption.bind(this);

    }
    ClosePopUp() {
        const { closePopUp } = this.state
      
        this.setState({
            closePopUp: !this.state.closePopUp
        }, () => {
           // this.props.OptionRequest({ closePopUp })
        })
    }

    getRecords() {
        this.setState({ loading: true })
        let newAr1 = this.state.chatboots;

        var newAr = calculatorData.chatboots.filter((res => res._id == this.state.clickId));
        setTimeout(() => {
            this.setState({
                chatboots: [...newAr1, ...newAr],
                loading: false
            }, () => {
                this.scrollDownMessage();
            })
        }, 2000);

        newAr.map((item, index) =>{

            if(item.next!=="")
            {
                setTimeout(() => {
                    this.setState({
                        clickId:item.next,
                    }, () => {
                        this. getRecords();
                    })
                }, 3000);
               
            }

        });

    }

    selectOptions = (id, name) => {
        console.log(id, name);
        this.setState({
            clickId: id,
            openSecureForm:false,
            openPaymenteForm:false
        })

        let selecteditem = [];
        selecteditem.push({
            name: name,
            _id: "",
            bootoptions: [
                {
                    chatBootId: "",
                    clickChatId: "",
                    name: "",
                    type: "text",
                }
            ]
        });

        let newAr = selecteditem;
        let newAr1 = this.state.chatboots;
        setTimeout(() => {
            this.setState({
                chatboots: [...newAr1, ...newAr],

            }, () => {
                console.log("suyash", this.state.chatboots)
                this.getRecords()
            });
        }, 500);
    }
    OpenSecureForm(clickId,formId){
        console.log(formId);
        if(formId==1)
        {
            this.setState({
                openSecureForm:true,
                clickId:clickId
            })
        }
        else if(formId==2)
        {
            this.setState({
                openPaymenteForm:true,
                clickId:clickId
            })
        }
     
    }
    FormSubmitedRes(clickChatId){
       console.log(clickChatId);
    }

    backOption = () => {
       
       this.props.backOption();

    }

    nextBlank(id){

        this.setState({
            clickId:id
        }, () => {
                this.getRecords()
            });

    }

    scrollDownMessage() {
        this.chat_scroll.current.scrollTop = this.chat_scroll.current.scrollHeight;
      }
    componentDidMount() {
        this.selectOptions(1,"$ Make a Payment")
    }
    
    render() {
        return (
            <div>
                <SecureForm data={{
                    openSecureForm:this.state.openSecureForm,
                    formId:"",
                    clickId:this.state.clickId,
                    selectOptions:this.selectOptions
                }} />
                <PaymentForm data={{
                    openPaymenteForm:this.state.openPaymenteForm,
                    formId:"",
                    clickId:this.state.clickId,
                    selectOptions:this.selectOptions
                }} /> 
                <div className="chat-position">
                    <div className="chat-window" style={{ marginBottom:"20px", display: this.state.closePopUp==false ? "none" : "" }}>
                        <div className="chatbox_parent" >
                            <div className="header">
                                <div className="logo_parent">
                                    <div className="logo">
                                        <img src={logo} alt="" />
                                    </div>
                                    <p className="milliennia f-bold">Milliennia</p>
                                </div>

                                <img onClick={this.ClosePopUp} className="cross" src={close} alt="" />
                            </div>

                            <div className="chatbox_inside_body">
                                <div className="messege_section" ref={this.chat_scroll}>
                                    {
                                        this.state.chatboots.map((chats, index) =>
                                            <React.Fragment>
                                               
                                                <div className="left_flow">
                                                    <p className="time">12:00 PM</p>
                                                    <div className="bot_msgs">

                                                        {chats._id != "" &&
                                                            <div className="bot_msg">
                                                                <p className="msg_from_bot">{chats.name}</p>
                                                                {
                                                                    this.state.chatboots.length - 1 == index && chats.bootoptions.length > 0 && chats.bootoptions.map((item, idx) =>
                                                                        
                                                                        item.name != "" &&
                                                                        this.state.chatboots.length - 1 == index && chats.bootoptions.length &&
                                                                        <div className='mt-1'>
                                                                            {
                                                                                item.type !== "link" ?  
                                                                            <Button color='primary'  outline onClick={() => this.selectOptions(item.clickChatId, item.name)} className='ml-1' size='sm'>{item.name}</Button>
                                                                             :
                                                                             <a href='javascript:;' onClick={()=>this.OpenSecureForm(item.clickChatId, item.formId)} color='info'><u>{item.name}</u></a>    
                                                                        }
                                                                            </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {index ==1 && this.state.chatboots.length - 1 == index && chats.bootoptions.length > 0 && 
                                                    <div className="left_flow">
                                                        <div className="bot_msgs">
                                                            <div className="bot_msg chat_btn">
                                                                <a href="javascript:;" onClick={()=>this.backOption()}  className="chat_action_btn"><i className="fa fa-long-arrow-left mr-2"></i>Back to main menu</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                 {index ==7 && 
                                                    <div className="left_flow">
                                                        <div className="bot_msgs">
                                                            <div className="bot_msg chat_btn">
                                                                <a href="javascript:;" onClick={()=>this.backOption()}  className="chat_action_btn"><i className="fa fa-file-pdf-o mr-2"></i>Get your invoice</a>
                                                                <a href="javascript:;" onClick={()=>this.backOption()}  className="chat_action_btn"><i className="fa fa-long-arrow-left mr-2"></i>Back to main menu</a>
                                                                <a href="javascript:;" onClick={() => this.nextBlank(8)} className="chat_action_btn"><i class="fa fa-window-close-o mr-2"></i>End chat</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    chats._id === "" &&
                                                    <div class="right_flow">
                                                        <p class="time">12:00 PM</p>
                                                        <div class="user_msgs">
                                                            <div class="user_msg option_selected">
                                                                <p class="msg_from_bot">{chats.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )}
                                    {
                                        this.state.loading &&
                                        <div class="left_flow">
                                            <div>
                                                
                                                <Spinner
                                                    color="dark"
                                                    type="grow"
                                                >
                                                    Loading...
                                                </Spinner>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    <div onClick={this.ClosePopUp} className="chat-button" >
                        Chat with us <img src={logo} className="ml-2" width="40" />
                    </div>
                </div>
            </div>
        )
    }
}
