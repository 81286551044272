import React, { Component } from 'react'
import secure from "../../assets/images/secure-icon.png"
import logo from "../../assets/images/Logo.png" 

export default class secureForm extends Component {
    constructor(){
        super();
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(){
        console.log("fdsfsd fsdf sdf");
        this.props.data.selectOptions(this.props.data.clickId,"The verification form has been submitted")

    }

    render() {
        console.log(this.props)
        const {openSecureForm} = this.props.data
        return (
            <>
                <div className="new-chat-window">
                    <div className="chat-window-sml verification_form chat_box_header" style={{display:openSecureForm ?"block":"none"}} >
                        <div className="header">
                            <div className="logo_parent">
                                <div className="logo">
                                    <img src={logo} alt="" />
                                </div>
                                <p className="milliennia f-bold">{openSecureForm} Verify your detail</p>
                            </div>
                            <a href="javascript:;"  className="options-close chat_close_icon"><i class="fa fa-times"></i></a>
                        </div>
                        <div className="secured_box">
                            <div className="secured_box_info py-1 px-15">
                                <span className="font-small">
                                    <img src={secure} alt="secure icon" className="mr-2" />Secured form
                                </span>
                                <span>
                                    <i className="fa fa-lock"></i>
                                </span>
                            </div>
                        </div>
                        <div className="secured_form_body py-5 px-15">
                            <div className="custom_form">
                                <div className="form-group row mt-1">
                                    <div className="col-md-4">
                                        <label for="">Payment Code</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control typebox_input" id="" placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label for="">Last Name</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control typebox_input" id="" placeholder="" />
                                    </div>
                                </div>
                                <div className="form-group text-right bot-options d-flex justify-content-end submit_btn">
                                    <button type="button"  onClick={this.handleSubmit} className="option-btn mt-1 mb-0">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
