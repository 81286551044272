import React, { Component } from 'react'
import secure from "../../assets/images/secure-icon.png"
import logo from "../../assets/images/Logo.png"
import payment from "../../assets/images/payment-option-img.jpg"

export default class secureForm extends Component {
    constructor() {
        super();
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit() {
        this.props.data.selectOptions(this.props.data.clickId, "Payment of $100 is paid")

    }
    render() {
        console.log(this.props)
        const { openPaymenteForm } = this.props.data
        return (
            <>
                <div className="new-chat-window">
                    <div className="chat-window-sml payment_form chat_box_header" style={{ display: openPaymenteForm ? "" : "none" }} >

                        <div className="header">
                            <div className="logo_parent">
                                <div className="logo">
                                    <img src={logo} alt="" />
                                </div>
                                <p className="milliennia f-bold">Complete your payment</p>
                            </div>
                            <a href="javascript:;"  className="options-close chat_close_icon"><i class="fa fa-times"></i></a>
                        </div>
                        <div className="secured_box">
                            <div className="secured_box_info py-1 px-15">
                                <span className="font-small">
                                    <img src={secure} alt="secure icon" className="mr-2" />Secured form
                                </span>
                                <span>
                                    <i className="fa fa-lock"></i>
                                </span>
                            </div>
                        </div>
                        <div className="secured_form_body py-2 px-15">
                            <div className="cards_img text-right">
                                <img src={payment} alt="image" className="img-fluid w-50" />
                            </div>
                            <div className="custom_form">
                                <div className="form-group">
                                    <label for="">Card number</label>
                                    <input type="text" className="form-control typebox_input" id="" placeholder="1234 5678 9012 3456" />
                                </div>
                                <div className="form-group">
                                    <label for="">Name on card</label>
                                    <input type="text" className="form-control typebox_input" id="" placeholder="Ex. Richard Deo" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label for="">Expiry Date</label>
                                            <input type="text" className="form-control typebox_input" id="" placeholder="02 / 25" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label for="">CVV</label>
                                            <input type="text" className="form-control typebox_input" id="" placeholder="***" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label >Enter Amount</label>
                                    <div className=" input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">$</div>
                                        </div>
                                        <input type="text" className="form-control typebox_input" id="" placeholder="100" style={{width: "85%"}} />
                                    </div>
                                </div>
                                <div className="form-group text-right bot-options d-flex justify-content-end submit_btn">
                                    <button type="button"  onClick={this.handleSubmit} className="option-btn mt-1 mb-0">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
